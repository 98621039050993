@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  margin: 0;
  padding: 0;
  font-family: 'IBM Plex Sans';
}

p {
  margin: 0;
  padding: 0;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 9999;
  border: 2px solid white;
  border-top: 2px solid blue;
  border-right: 2px solid blue;
}

.hideScrollBar {
  overflow: hidden;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 70px;
}

.widthSmall {
  width: 300px;
}
